import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@maplix/api';
import { Map } from 'ol';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapExportService {
  constructor(@Inject('environment') private environment: any, private api: ApiService) {}

  public getImageCanvas(map: Map, resize?: boolean): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      map.once('postrender', () => {
        // According to <https://openlayers.org/en/latest/examples/export-map.html> this should be once('rendercomplete'),
        // but that somehow does not enable Heatmap layers to show in the exported map.
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        let widthRatio,
          heightRatio,
          resizingFactor = 1;

        if (resize) {
          widthRatio = 500 / size[0];
          heightRatio = 500 / size[1];
          if (!(widthRatio >= 1 && heightRatio >= 1)) {
            resizingFactor = widthRatio < heightRatio ? widthRatio : heightRatio;
          }
          mapCanvas.width = Math.round(size[0] * resizingFactor);
          mapCanvas.height = Math.round(size[1] * resizingFactor);
        }

        const mapContext = mapCanvas.getContext('2d');

        Array.prototype.forEach.call(map.getViewport().querySelectorAll('.ol-layer canvas, canvas'), (canvas) => {
          if (canvas.width <= 0) {
            return;
          }

          const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }

          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
          if (!resize || (widthRatio >= 1 && heightRatio >= 1)) {
            mapContext.drawImage(canvas, 0, 0);
            return;
          }

          mapContext.drawImage(
            canvas,
            0,
            0,
            Math.round(canvas.width * resizingFactor),
            Math.round(canvas.height * resizingFactor)
          );
        });

        mapContext.globalAlpha = 1;

        try {
          resolve(mapCanvas);
        } catch (error) {
          console.error(error);

          console.error(
            'Export to png failed. If you are using Microsoft Edge, please switch to another browser like Google Chrome'
          );

          reject();
        }
      });

      map.renderSync();
    });
  }

  public dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = dataURI.split(',')[1];
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  public async uploadThumbnailToS3(imageCanvas: HTMLCanvasElement) {
    try {
      if (imageCanvas) {
        const imageString = imageCanvas.toDataURL();
        // Upload image to S3 and get the url
        const fileurl = await this.api.files
          .upload(this.dataURItoBlob(imageString), true)
          .pipe(
            filter((event) => event instanceof HttpResponse),
            map((event) => `${this.environment.api}${event.body.file.file}`)
          )
          .toPromise();

        return fileurl;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
