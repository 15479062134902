import { Map, View } from 'ol';
import { defaults as DefaultInteractions } from 'ol/interaction';
import Projection from 'ol/proj/Projection';
import { Layer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import { Icon, Style, Stroke, Fill, Circle } from 'ol/style';
import XYZ from 'ol/source/XYZ';
import * as styleUtils from '@maplix/utils';
import { IMapWMS, IMapWMSLayer, THEME_COLORS } from '@maplix/utils';
import { Coordinate } from 'ol/coordinate';
import { MapClsGeneric } from './mapcls-generic';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ScaleLine, Zoom } from 'ol/control';
import { toLonLat, transform } from 'ol/proj';
import { Source } from 'ol/source';

import mapboxgl from 'mapbox-gl';
import { BASELAYERS } from '../utils';

export interface ILegendUrl {
  id: string;
  title: string;
  legends: any[];
  visible: boolean;
}

export class MapCls extends MapClsGeneric {
  public lDrawRoute: VectorLayer<VectorSource>;
  public lMarker: VectorLayer<VectorSource>;

  public WMSLegendUrls: ILegendUrl[] = [];

  private mapboxMap: mapboxgl.Map;

  constructor(
    public target: string,
    translate: TranslateService,
    http: HttpClient,
    environment: any,
    baseLayer: string = 'mapbox-basic',
    center?: Coordinate,
    zoom?: number,
    private isStatic: boolean = false
  ) {
    super(target, translate, http, environment);
    this.init(center, zoom);
    this.addBaseLayer(baseLayer, center, zoom);
  }

  init(center: Coordinate = [0, 0], zoom: number = 2) {
    const controls = this.isStatic ? [] : [new Zoom(), new ScaleLine()];

    this.map = new Map({
      target: this.target,
      layers: [],
      interactions: DefaultInteractions({
        doubleClickZoom: this.isStatic ? false : true,
        mouseWheelZoom: this.isStatic ? false : true,
        dragPan: this.isStatic ? false : true,
      }),
      view: new View({
        projection: new Projection({
          code: 'EPSG:3857',
          units: 'm',
        }),
        center,
        zoom,
        maxZoom: 22,
      }),
      controls,
      moveTolerance: this.isTouchScreen ? 6 : 1,
    });

    this.center.next(center);
    this.zoom.next(zoom);

    this.map.getView().on('change', () => {
      this.center.next(this.map.getView().getCenter());
      this.zoom.next(this.map.getView().getZoom());
    });
  }

  addBaseLayer(baseLayer: string, center: any = [0, 0], zoom: number = 2) {
    mapboxgl.accessToken = this.environment.mapboxToken;

    const transformedPoint = transform(center, 'EPSG:3857', 'EPSG:4326');
    this.mapboxMap = new mapboxgl.Map({
      style: BASELAYERS.find((bl) => bl.id === baseLayer).styleUrl,
      attributionControl: false,
      boxZoom: false,
      container: this.target,
      center: transformedPoint as any,
      zoom,
      doubleClickZoom: false,
      dragPan: false,
      dragRotate: false,
      interactive: false,
      keyboard: false,
      pitchWithRotate: false,
      scrollZoom: false,
      touchZoomRotate: false,
      preserveDrawingBuffer: true,
    });

    const mbMap = this.mapboxMap;

    const mbLayer = new Layer({
      render: function (frameState) {
        const canvas = mbMap.getCanvas();
        const viewState = frameState.viewState;

        const visible = mbLayer.getVisible();
        canvas.style.display = visible ? 'block' : 'none';
        canvas.style.position = 'absolute';

        // adjust view parameters in mapbox
        const rotation = viewState.rotation;
        mbMap.jumpTo({
          center: <any>toLonLat(viewState.center),
          zoom: viewState.zoom - 1,
          bearing: (-rotation * 180) / Math.PI,
        });

        return canvas;
      },
      source: new Source({}),
    });

    mbLayer.set('name', 'mapbox-basic');
    mbLayer.set('base', true);
    this.map.addLayer(mbLayer);
  }

  updateMapSize() {
    if (this.map) {
      this.map.updateSize();
    }

    if (this.mapboxMap) {
      this.mapboxMap.resize();
    }
  }

  setBaseLayer(baseLayer: string) {
    const url = BASELAYERS.find((bl) => bl.id === baseLayer).styleUrl;
    this.mapboxMap.setStyle(url);
  }

  addDrawLayer() {
    this.lDraw = new VectorLayer({
      source: new VectorSource({}),
      style: (f) => {
        return styleUtils.drawStyle(f);
      },
      zIndex: 40000,
    });

    this.lDraw.set('base', false);
    this.lDraw.set('name', 'lDraw');

    this.lDrawRoute = new VectorLayer({
      source: new VectorSource({}),
      style: (feature) => {
        const bg = new Style({
          image: new Circle({
            radius: feature.get('tmIcon') ? 10 : 9,
            stroke: new Stroke({
              color:
                feature.get('drawStyle')?.lineBorderWidth >= 1
                  ? feature.get('drawStyle')?.lineBorderColor
                  : feature.get('drawStyle')?.color,
              width: 2,
            }),
            fill: new Fill({
              color: feature.get('tmIcon') ? 'white' : feature.get('drawStyle')?.color,
            }),
          }),
        });

        const icon = new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: `assets/images/map/${feature.get('tmIcon')}.png`,
            scale: 0.14,
            rotateWithView: true,
            color: THEME_COLORS.GENERIC.FONT,
          }),
        });

        return feature.get('tmIcon') ? [bg, icon] : bg;
      },
      zIndex: 40000,
    });

    this.lDrawRoute.set('base', false);
    this.lDrawRoute.set('name', 'lDrawRoute');

    this.lMarker = new VectorLayer({
      source: new VectorSource({}),
      style: new Style({
        image: new Icon({
          src: 'assets/images/map/marker-2.png',
          anchor: [0.5, 1],
          scale: 0.05,
        }),
      }),
      zIndex: 30000,
    });

    this.lMarker.set('base', false);
    this.lMarker.set('name', 'lMarker');

    this.map.addLayer(this.lDraw);
    this.map.addLayer(this.lDrawRoute);
    this.map.addLayer(this.lMarker);
  }

  public addWMS(config: IMapWMS, zIndex: number, layer?: IMapWMSLayer): TileLayer<TileWMS> {
    const wmsLayer = new TileLayer({
      source: new TileWMS({
        url: config.url,
        params: {
          FORMAT: 'image/png',
          CRS: '3857',
          LAYERS: layer
            ? [layer.name]
            : (config as IMapWMS).layers
            ? (config as IMapWMS).layers
                .filter((layer) => layer.selectedInModal && layer.name)
                .map((layer) => layer.name)
            : '',
        },
        crossOrigin: 'anonymous',
      }),
      visible: layer ? layer.selectedInMap : config.selectedInMap,
      minZoom: config.minZoom ? config.minZoom : 0,
      maxZoom: config.maxZoom ? config.maxZoom : 22,
      opacity: (config as IMapWMS).opacity || (config as IMapWMS).opacity === 0 ? (config as IMapWMS).opacity / 100 : 1,
    });

    wmsLayer.setZIndex(zIndex);
    wmsLayer.set('name', config.title);
    wmsLayer.set('maplixId', layer ? layer.id : config.id);

    this.getWMSLegend(config, layer);

    return wmsLayer;
  }

  protected getWMSLegend(config: IMapWMS, layer: IMapWMSLayer) {
    const legends = [];
    legends.push({
      url:
        config.url.split('?')[0] +
        '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=' +
        layer.name,
      name: layer.name,
    });

    const urlObj: ILegendUrl = {
      id: layer.id,
      title: layer.title as string,
      legends: legends,
      visible: false,
    };

    this.WMSLegendUrls = this.WMSLegendUrls.filter((url) => url.id !== urlObj.id);
    this.WMSLegendUrls.push(urlObj);
  }

  public getZoom() {
    return this.zoom.value;
  }

  public getCenter() {
    return this.center.value;
  }
}
